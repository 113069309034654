html,
body {
  background: #f0f2f5;
  font-family: PingFangSC-Medium;
}
h3 {
  font-size: 14px;
  line-height: 20px;
}

/**
 * antd 菜单缩进的时候，高亮 openKeys
 */
ul.ant-menu-vertical li.ant-menu-submenu-selected {
  background-color: #224065;
}

ul.ant-menu-vertical.ant-menu-inline-collapsed > li.ant-menu-item,
ul.ant-menu-vertical.ant-menu-inline-collapsed
  > li.ant-menu-submenu
  .ant-menu-submenu-title {
  height: 80px;
  padding: 10px 0 !important;
  margin: 0;
  text-align: center;
  line-height: 20px;
}

ul.ant-menu-vertical.ant-menu-inline-collapsed > li.ant-menu-item i.anticon {
  font-size: 30px;
}

ul.ant-menu-vertical.ant-menu-inline-collapsed
  > li.ant-menu-submenu.ant-menu-submenu-vertical
  i.anticon {
  font-size: 30px;
}

ul.ant-menu-vertical.ant-menu-inline-collapsed
  > li.ant-menu-item
  i.anticon
  + span {
  display: block !important;
  opacity: 1;
  max-width: 80px;
}

ul.ant-menu-vertical.ant-menu-inline-collapsed
  > li.ant-menu-submenu.ant-menu-submenu-vertical
  i.anticon
  + span {
  display: block !important;
  opacity: 1;
  max-width: 80px;
}

/** 全局报警提醒的样式 */
.ant-notification-notice.alert-level3-warn {
  background-color: red;
}

.ant-notification-notice.alert-level3-warn .ant-notification-notice-message,
.ant-notification-notice-icon,
.ant-notification-notice-description,
.anticon.ant-notification-close-icon {
  color: white;
}
